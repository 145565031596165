@color-red : #bf0808;
@color-blue: #0649af;
@color-grey: #a8a8a847;

@color-bg-light-red: #b1a8a847;
@color-bg-light-blue: #c2c7ce61;

:root {
  font-size: .9rem;
  font-family: sans-serif;
  padding-bottom: 40px;
}

body {
  margin: 0;
}

* + * {
  margin-top: 4px;
  margin-right: 5px;
}

hr {
  margin: 12px 0 5px;
  padding: 0;
  border-color: #ffffffb3;
}

h1 {
  margin: 8px 0 6px;
}

h2 {
  margin: 6px 0 4px;
}

h3 {
  margin: 4px 0 2px;
}

input, select, textarea {
  box-sizing: border-box;
  max-width: 100%;
}

input, select, button {
  padding: 4px;
}

input[type="text"] {
  width: 480px;
  border-color: #cfcfcf9c;
  border-width: 1px;
}

input[type="text"].addr-name {
  width: 180px;
}

button {
  min-width: 85px;
}

pre {
  margin: 0;
  padding: 10px 0px;
  border-bottom: solid 1px @color-grey;
}

table th {
  text-align: left;
}

a, a.visited, a.hover {
  color: @color-blue;
}

optgroup > option {
  color: #000;
}

.testnet-color {
  color: @color-blue;
}

.mainnet-color {
  color: @color-red;
}

.rev {
  font-size: 1.2rem;
}

.testnet {
  h2 {
    .testnet-color
  }

  .address-ctrl {
    background-color: @color-bg-light-blue;
  }

  .add-account:not([disabled]) {
    .testnet-color;
    font-weight: bold;
  }
}

.mainnet {
  h2 {
    .mainnet-color
  }

  .address-ctrl {
    background-color: @color-bg-light-red;
  }

  .add-account:not([disabled]) {
    .mainnet-color;
    font-weight: bold;
  }
}

.ctrl {
  padding-left: 10px;
  padding-right: 10px;
}

.selector-ctrl {
  padding-bottom: 5px;

  h2 {
    display: inline-block;
    padding-right: 10px;;
  }

  table {
    font-size: .8em;
    color: #3c3c3c;
  }
}

.selector-ctrl pre {
  display:inline-block;
  padding: 0px 0px;
  border: none;
}

.address-ctrl {
  padding-top: 15px;
  padding-bottom: 25px;
  background-color: @color-grey;

  .info {
    font-size: .9rem;
  }
}


.transfer-ctrl .rev-amount {
  width: 195px;
}


.custom-deploy-ctrl {
  .deploy-code {
    width: 730px;
  }

  .phlo-limit {
    width: 200px;
  }
}

.warning {
  margin: 0;
  padding: 5px 0;
  color: @color-red;
  font-weight: bold;
}

.wallet {
  width: 500px;
}

.account {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

// Hard Fork info
.hf-info {
  padding: 15px 20px;
  background-color: #0d9a25;
  color: white;
  font-size: 1.5em;

  * + * {
    margin: 0;
  }
}
