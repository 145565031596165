:root {
  padding-bottom: 40px;
  font-family: sans-serif;
  font-size: .9rem;
}

body {
  margin: 0;
}

* + * {
  margin-top: 4px;
  margin-right: 5px;
}

hr {
  border-color: rgba(255, 255, 255, .7);
  margin: 12px 0 5px;
  padding: 0;
}

h1 {
  margin: 8px 0 6px;
}

h2 {
  margin: 6px 0 4px;
}

h3 {
  margin: 4px 0 2px;
}

input, select, textarea {
  box-sizing: border-box;
  max-width: 100%;
}

input, select, button {
  padding: 4px;
}

input[type="text"] {
  width: 480px;
  border-width: 1px;
  border-color: rgba(207, 207, 207, .61);
}

input[type="text"].addr-name {
  width: 180px;
}

button {
  min-width: 85px;
}

pre {
  border-bottom: 1px solid rgba(168, 168, 168, .28);
  margin: 0;
  padding: 10px 0;
}

table th {
  text-align: left;
}

a, a.visited, a.hover {
  color: #0649af;
}

optgroup > option {
  color: #000;
}

.testnet-color {
  color: #0649af;
}

.mainnet-color {
  color: #bf0808;
}

.rev {
  font-size: 1.2rem;
}

.testnet h2 {
  color: #0649af;
}

.testnet .address-ctrl {
  background-color: rgba(194, 199, 206, .38);
}

.testnet .add-account:not([disabled]) {
  color: #0649af;
  font-weight: bold;
}

.mainnet h2 {
  color: #bf0808;
}

.mainnet .address-ctrl {
  background-color: rgba(177, 168, 168, .28);
}

.mainnet .add-account:not([disabled]) {
  color: #bf0808;
  font-weight: bold;
}

.ctrl {
  padding-left: 10px;
  padding-right: 10px;
}

.selector-ctrl {
  padding-bottom: 5px;
}

.selector-ctrl h2 {
  padding-right: 10px;
  display: inline-block;
}

.selector-ctrl table {
  color: #3c3c3c;
  font-size: .8em;
}

.selector-ctrl pre {
  border: none;
  padding: 0;
  display: inline-block;
}

.address-ctrl {
  background-color: rgba(168, 168, 168, .28);
  padding-top: 15px;
  padding-bottom: 25px;
}

.address-ctrl .info {
  font-size: .9rem;
}

.transfer-ctrl .rev-amount {
  width: 195px;
}

.custom-deploy-ctrl .deploy-code {
  width: 730px;
}

.custom-deploy-ctrl .phlo-limit {
  width: 200px;
}

.warning {
  color: #bf0808;
  margin: 0;
  padding: 5px 0;
  font-weight: bold;
}

.wallet {
  width: 500px;
}

.account {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.hf-info {
  color: #fff;
  background-color: #0d9a25;
  padding: 15px 20px;
  font-size: 1.5em;
}

.hf-info * + * {
  margin: 0;
}

/*# sourceMappingURL=index.10b7185a.css.map */
